<template>
  <div class="list">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click="getPdf(item)"
    >
      <div class="left">
        <img src="../assets/pdf-icon.png" alt="" />
      </div>
      <div class="msg">
        <p class="name">{{ item.name }}</p>
        <div class="bottom">
          <p class="time">{{ item.time }}</p>
          <p class="internal-memory">{{ item.size }}KB</p>
        </div>
      </div>
      <div class="right">
        <img src="../assets/arrowleft-icon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRateTableFileByProductId,
  getProductAttachment,
} from "../utils/api";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  async created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    });
    let params = {
      id: this.$route.query.id,
    };
    if (this.$route.query.type == "rateTable") {
      await this.getRateTableFileByProductId(params);
    } else {
      await this.getProductAttachment(params);
    }
    this.$toast.clear();
  },
  methods: {
    //查看pdf
    getPdf(item) {
      this.$router.push({
        path: "/pdf",
        query: {
          id: item.id,
          productId:this.$route.query.id
        },
      });
    },
    //费率表
    getRateTableFileByProductId(params) {
      getRateTableFileByProductId(params).then((res) => {
        this.list = res.result;
      });
    },
    //产品条款
    getProductAttachment(params) {
      getProductAttachment(params).then((res) => {
        this.list = res.result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 0 20px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
  .left {
    img {
      width: 24px;
    }
  }
  .msg {
    flex: 1;
    width: 100%;
    margin: 0 17px;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: justify;
    }
    .bottom {
      display: flex;
      align-items: center;
      margin-top: 8px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 12px;
      }
    }
  }
  .right {
    img {
      width: 10px;
    }
  }
}
</style>